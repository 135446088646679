import { message } from 'antd';
import {sleep} from '@/utils';
import {refreshToken} from '@/api'

const isPro = process.env.NODE_ENV === 'production'
const API_URL = process.env.REACT_APP_API_URL

const NOT_TOKEN_API = [
  '/oauth2/token','/captcha/email',
  '/front/report','/available/date',
  '/cost/date'
]
export default async function sendRequest(url:any, options?:any) {
  const {
    method = 'GET', 
    headers = {
      'Content-Type': 'application/json',
      'Authorization':'Basic anR4LWx1bmFyOjEyMzQ1Ng==',
    }, 
    body = null
  } = options||{}
  console.log('/request.ts [20]--1',url,NOT_TOKEN_API.findIndex((s:string)=>url.includes(s))>-1);
  const tokenInfo = JSON.parse(localStorage.getItem('tokenInfo')||'{}')
  // 非token接口，headers需要添加Authorization
  if(
    // !/(oauth2\/token)|(captcha\/email)|(\/front\/report)|(\/available\/date)|(\/cost\/date)/.test(url)
    NOT_TOKEN_API.findIndex((s:string)=>url.includes(s))===-1
  ) {
    if(tokenInfo && tokenInfo.access_token) {
      headers['Authorization'] = 'Bearer ' + tokenInfo.access_token
    }else {
      message.error('token 丢失请重新登陆')
      await sleep(1000)
      message.error('正在跳转登录页')
      await sleep(1000)
      window.location.href = '/login'
      // const url = encodeURIComponent(window.location.href)
      // window.location.href="/login?redirect="+url
      // token()
    }
  }else {
  }

  // 构建请求配置
  const requestOptions = {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  };

  const _url  = (isPro?API_URL+'/':'')+ url
  // 发起请求并返回 Promise 对象
  return fetch(_url, requestOptions)
    .then(async (response) => {
      const { status } =  response
      let res = await response.json()
      // console.log('/request.ts [47]--1',res);
      // 响应后的逻辑
      // if(res.code) 
      // return await response.json()
      // return res
      if(status ===200) {
        // 解析响应数据
        return res;
      }else if(status ===401 && NOT_TOKEN_API.findIndex((s:string)=>url.includes(s))===-1) {
        message.error('token过期，正在重新获取')
        await refreshToken()
      }else {
        res && message.error(res.message||res.error_description)
        return res||{}
      }
    })
    .catch(async(error) => {
      // 错误处理逻辑
      message.error('请求失败')
      return {error:'error'}
      
    })
}

export async function exportRequest(url:any, options?:any) {
  const {
    method = 'GET', 
    headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization':'Basic anR4LWx1bmFyOjEyMzQ1Ng==',
    }, 
    body = null
  } = options||{}

  const tokenInfo = JSON.parse(localStorage.getItem('tokenInfo')||'{}')
  // 非token接口，headers需要添加Authorization
  if(tokenInfo && tokenInfo.access_token) {
    headers['Authorization'] = 'Bearer ' + tokenInfo.access_token
  }else {
    message.error('token 丢失请重新登陆')
    await sleep(1000)
    message.error('正在跳转登录页')
    await sleep(1000)
    window.location.href = '/login'
    // const url = encodeURIComponent(window.location.href)
    // window.location.href="/login?redirect="+url
    // token()
  }
  
  // 构建请求配置
  const requestOptions = {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  };

  const _url  = (isPro?API_URL+'/':'')+ url
  // 发起请求并返回 Promise 对象
  return fetch(_url, requestOptions)
    .then(async (response) => {
      const blob = await response.blob()
      // const downloadLink = document.createElement('a');
      // downloadLink.href = URL.createObjectURL(blob);
      // downloadLink.download = '数据导出.xlsx';

      // // 触发下载
      // downloadLink.click();

      // // 清理下载链接
      // URL.revokeObjectURL(downloadLink.href);
      return blob
    })
    .catch(async(error) => {
      // 错误处理逻辑
      message.error('请求失败')
      return {error:'error'}
      
    })
}