import { message } from 'antd';
// const querystring = require('querystring');
import qs from 'query-string';
import fetch from './request';
import {sleep} from '@/utils'

// 输出：name=John&age=30&city=New%20York

// login
// https://api-gateway-sit.alphawrite.cn/doc.html#/
// ///aw-auth/oauth2/token?grant_type=urn%3Aietf%3Aparams%3Aoauth%3Agrant-type%3Aaccount_password
// &account_name=guest01&account_password=jtx123
// &scope=alpha_scope&image_captcha=1&image_captcha_id=1’
export function token (params:any) {
  // 
  const _query = {
    // grant_type: 'urn:ietf:params:oauth:grant-type:account_password',
    grant_type: 'urn:ietf:params:oauth:grant-type:email_code',
    scope:'lunar_scope',
    // image_captcha: 1,
    // image_captcha_id:1,
    // account_name: params.user,
    // account_password: params.password,
    email: params.email,
    email_code: params.email_code
  }
  const query = qs.stringify(_query)
  return fetch(
    `/api/auth/oauth2/token?${query}`,
    {
    method:'POST',
  }).then((res:any)=>{
    if(res?.access_token) {
      localStorage.setItem('tokenInfo',JSON.stringify(res))
    }else {
      localStorage.setItem('tokenInfo',JSON.stringify({}))
      // message.error(res.message)
      // window.location.href = '/login'
    }
    return res
  }).catch(async (err:any)=>{
    console.log('Token===err',err)
    localStorage.setItem('tokenInfo',JSON.stringify({}))
    message.error('token获取异常')
    await sleep(1000)
    message.error('正在跳转登录页')
    await sleep(1000)
    const url = encodeURIComponent(window.location.href)
    window.location.href="/login?redirect="+url
  })
}
// https://api-gateway-sit.alphawrite.cn/aw-auth/oauth2/token
// ?refresh_token=_J4MRT5cgoTZ5kTpsSBJunOwgsEi0WqhCbuDY0lMZ4bDBvfZ5KneOlsxMy1nHUYTjM6q3HqwKNLXykrVxba0Vji61jMspOa-w7wiZyQ7SD5VI1msIg9o1lyRvne_qT7e
// &grant_type=refresh_token
export function refreshToken () {
  const tokenInfo = JSON.parse(localStorage.getItem('tokenInfo')||'{}')
  // 非token接口，headers需要添加Authorization
  if(tokenInfo && tokenInfo.access_token) {
    const params = {
      grant_type: 'refresh_token',
      refresh_token: tokenInfo.refresh_token
    }
    const query = qs.stringify(params)
    fetch(
      `/api/auth/oauth2/token?${query}`,
      {
      method:'POST',
    }).then((res:any)=>{
      if(res?.access_token) {
        localStorage.setItem('tokenInfo',JSON.stringify(res))
        // window.location.href = window.location.href
        window.location.reload()
      }else {
        localStorage.setItem('tokenInfo',JSON.stringify({}))
        window.location.href="/login"
      }
    }).catch(err=>{
      console.log('refreshToken===err',err)
      localStorage.setItem('tokenInfo',JSON.stringify({}))
      window.location.href="/login"
    })
  }
}

export async function emailCode  (params:any) {
  return fetch(`/api/auth/console/captcha/email`,{
    method: 'POST',
    body: params
  })
}

export async function menuPermisson  () {
  return fetch(`/api/auth/console/menu`,{
    method: 'GET',
  })
}